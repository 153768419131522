import React from "react";

const ErrorView = ({ message }) => {
  console.log({ message });

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="error_view">
      <i class="fa fa-exclamation-triangle"></i>
      <h2>Oops, Something went wrong</h2>
      {/* <p>Error: {message}</p> */}
      {/* <button
        type="button"
        onClick={(...args) => handleReload(...args)}
        className="btn btn-primary button-md btn-block"
      >
        Go Home
      </button> */}
    </div>
  );
};

export default ErrorView;
