export class Dashboard extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Dashboard");
    this.state = {
      dashboardData: [
        {
          content: "-",
          label: "Total Orders",
          icon: imagepaths.clipEditIcon,
        },
        {
          content: "-",
          label: "Open Orders",
          icon: imagepaths.clipOpenIcon,
        },
        {
          content: "-",
          label: "Completed Orders",
          icon: imagepaths.clipCheckIcon,
        },
        {
          content: "-",
          label: "Cancelled Orders",
          icon: imagepaths.clipXmarkIcon,
        },
      ],
    };
  }

  componentDidMount() {
    this.getDashboardData();
  }

  getDashboardData() {
    this.props.api.apiGetPartyOrderCounts().then(({ data, total }) => {
      let totalRecords = data[0]?.totalRecords;
      let statusCounts = data[0]?.statusCounts;

      this.setState({
        dashboardData: [
          {
            content: totalRecords || "-",
            label: "Total Orders",
            icon: imagepaths.clipEditIcon,
          },
          {
            content:
              statusCounts?.filter((v) => v?.status === "OPEN")[0]?.count ||
              "-",
            label: "Open Orders",
            icon: imagepaths.clipOpenIcon,
          },
          {
            content:
              statusCounts?.filter((v) => v?.status === "COMPLETED")[0]
                ?.count || "-",
            label: "Completed Orders",
            icon: imagepaths.clipCheckIcon,
          },
          {
            content:
              statusCounts?.filter((v) => v?.status === "CANCELLED")[0]
                ?.count || "-",
            label: "Cancelled Orders",
            icon: imagepaths.clipXmarkIcon,
          },
        ],
      });
    });
  }

  render() {
    let { dashboardData } = this.state;
    return (
      <AppWrapper title="Dashboard" hidebreadcrum={true}>
        <div className="row">
          {dashboardData?.map((item, k) => {
            return (
              <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={k}>
                <div className="card dash-widget dashboard_card">
                  <div className="card-body py-4">
                    <div className="icon_box me-3">
                      <img src={item.icon} alt="" />
                    </div>
                    <div className="dash-widget-info">
                      <p>{item.label}</p>
                      <h3>{item.content}</h3>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </AppWrapper>
    );
  }
}

export default connect(Dashboard);
