export const devapis = {
    heartbeat: {
        "method": "get",
        "url": "/v1/heartbeat"
    },
    login: {
        "method": "post",
        "url": "/v1/auth/login"
    },
    reset: {
        "method": "post",
        "url": "/v1/auth/reset"
    },
    refresh: {
        "method": "post",
        "url": "/v1/auth/refresh"
    },
    logout: {
        "method": "post",
        "url": "/v1/auth/logout"
    },
    password: {
        "method": "put",
        "url": "/v1/auth/password"
    },
    // FILE API'S
    file_upload: {
        url: "/files/upload/{foldername}",
        method: "post"
    },
    image_file_upload: {
        url: "/v1/generatepdf",
        method: "post"
    },
    image_file_delete: {
        url: "/v1/generatepdf/{attachment_id}",
        method: "delete"
    },
    generate_party_pdf: {
        url: "/v1/generatepdf/party",
        method: "post"
    },
    // PROFILE API's
    create: {
        "method": "post",
        "url": "/v1/profile/new"
    },
    all: {
        "method": "get",
        "url": "/v1/profile/all"
    },
    me: {
        "method": "get",
        "url": "/v1/profile/me"
    },
    updateprofile: {
        "method": "put",
        "url": "/v1/profile/update"
    },
    // MENU API's
    addMenuItem: {
        "method": "post",
        "url": "/v1/menu/add"
    },
    getMenuItems: {
        "method": "get",
        "url": "/v1/menu/{location}"
    },
    updateMenuItem: {
        "method": "put",
        "url": "/v1/menu/edit"
    },
    deleteMenuItem: {
        "method": "delete",
        "url": "/v1/menu/delete"
    },
    addMenuFile: {
        "url": "/v1/menu-file",
        "method": "post",
    },
    // EMPLOYEES API's
    getEmployees: {
        "method": "get",
        "url": "/v1/employee/get/{location}"
    },
    getEmployeeById: {
        "method": "get",
        "url": "/v1/employee/get/employee/{employeeId}"
    },
    addEmployee: {
        "method": "post",
        "url": "/v1/employee/add"
    },
    editEmployee: {
        "method": "put",
        "url": "/v1/employee/edit/{location}/{employeeId}"
    },
    deleteEmployee: {
        "method": "delete",
        "url": "/v1/employee/delete/{employeeId}"
    },
    // CART API's
    addCartItem: {
        "method": "post",
        "url": "/v1/cart/add"
    },
    addCartItemById: {
        "method": "put",
        "url": "/v1/cart/{order_id}"
    },
    submitCartItem: {
        "method": "get",
        "url": "/v1/cart/submit/{order_id}"
    },
    getConfirmedOrders: {
        "method": "get",
        "url": "/v1/confirmedorders"
    },
    // PARTY ORDER API's
    addPartyOrder: {
        "method": "post",
        "url": "/v1/partyorder/add"
    },
    updatePartyOrder: {
        "method": "put",
        "url": "/v1/partyorder/{order_id}"
    },
    getAllPartyOrder: {
        "method": "get",
        "url": "/v1/partyorder/get"
    },
    getPartyOrderById: {
        "method": "get",
        "url": "/v1/partyorder/get/{order_id}"
    },
    // DASHBOARD API's 
    getPartyOrderCounts: {
        "method": "get",
        "url": "/v1/dashboard"
    },

}

export default devapis;