import React, { Component } from "react";
import MenulistNavbar from "../MenulistNavbar";
import mixinsFunc from "../mixins";
import SpecialItems from "../SpecialItems";
import { Link } from "react-router-dom";

export class MenuList extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("MenuList");
    this.mixins = mixinsFunc(this);
    this.state = {
      activeButton: "appetizers",
      foodLists: [],
      menuData: [],
      selectedCategory: "appetizers",
      search: {
        limit: 10,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
        location: "BiryaniCityMaryLand",
      },
      categoryLists: this.mixins.menuCategories,
    };
  }

  get location() {
    return this.props.urlparams.location;
  }

  get tablenum() {
    return this.props.urlparams.table_number;
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList(value) {
    let payload = {
      location: this.location.toTitleCase(),
    };
    let logindata = {
      email_address: "admin@bcity.com",
      password: "aaBB11##",
    };
    this.props.api
      .apiLogin(logindata)
      .then((res) => {
        console.log("apiLogin", res);
      })
      .then(() => {
        this.props.api.apiGetMenuItems("", payload).then(({ data }) => {
          console.log({ apiGetMenuItems: data });
          this.setState({
            foodLists: data,
          });
        });
      });
  }

  onButtonClick(buttonName) {
    if (buttonName === "allitems") {
      this.setState({
        ...this.state,
        selectedCategory: buttonName,
        activeButton: this.state.activeButton === buttonName ? "" : buttonName,
      });
    } else {
      this.setState({
        ...this.state,
        selectedCategory: buttonName,
        activeButton: this.state.activeButton === buttonName ? "" : buttonName,
      });
    }
  }

  // RENDER SUB-CATEGORY IMAGES
  renderImages(arr, val) {
    console.log({ arr, val });
    // CHECK FOR SUB CATEGORY
    let subCategoriesArr = [];
    if (arr[0]?.hasOwnProperty("item_sub_category")) {
      console.log(arr[0]);
      if (arr[0]?.item_sub_category === "") {
        subCategoriesArr.push(arr[0]?.item_category);
      } else {
        let allSubs = arr?.map((item, i) => item.item_sub_category);
        allSubs = allSubs.filter((c) => c !== undefined);
        subCategoriesArr = [...new Set(allSubs)];
      }
    } else {
      subCategoriesArr = [];
      // console.log("no data found");
    }
    // console.log("subCategoriesArr:", subCategoriesArr);
    return subCategoriesArr;
  }
  // SET SUBCATEGORIES TO CAROUSEL SLIDER
  filterByCategory(arr, item) {
    item = item.toUpperCase();
    let filteredArr = arr.filter(
      (key) =>
        key?.item_category?.replace(/\s/g, "").toUpperCase() ===
        item.toUpperCase()
    );
    return filteredArr;
  }

  filterData(data) {
    let filteredCategory = [];

    if (this.state.selectedCategory === "allitems") {
      filteredCategory.push(...data);
      let renderdata = this.renderImages(
        filteredCategory,
        this.state.selectedCategory
      );
      console.log({ filteredCategory, renderdata });
      return renderdata;
    } else if (this.state.selectedCategory === "dessert_and_beverages") {
      let dessert = this.filterByCategory(data, "Dessert");
      let beverages = this.filterByCategory(data, "Beverages");
      filteredCategory.push(...dessert, ...beverages);
      let renderdata = this.renderImages(
        filteredCategory,
        this.state.selectedCategory
      );
      console.log({ filteredCategory, renderdata });
      return renderdata;
    } else {
      let result = this.filterByCategory(data, this.state.selectedCategory);
      filteredCategory.push(...result);
      let renderdata = this.renderImages(
        filteredCategory,
        this.state.selectedCategory
      );
      console.log({ filteredCategory, renderdata });
      return renderdata;
    }
  }

  render() {
    let { activeButton, foodLists, categoryLists } = this.state;
    let filteredData = this.filterData(foodLists);
    return (
      <>
        <MenulistNavbar />
        <div className="menu_content">
          <div className="menu_title_box">
            <h2>What's on your mind</h2>
            <img src={imagepaths.seperatorIcon} alt="seperatorIcon" />
          </div>
          <div className="menu_filter_btn_container">
            {categoryLists?.map((item, i) => {
              return (
                <button
                  key={i}
                  type="button"
                  className={`${
                    activeButton === `${item?.name}` ? "active" : ""
                  }`}
                  onClick={() => this.onButtonClick(`${item?.name}`)}
                >
                  {item?.label}
                </button>
              );
            })}
          </div>
          {/* FOOD LIST */}
          <div className="food_items_container row g-3 mx-auto">
            {filteredData?.map((item, i) => {
              return (
                <MenuItem
                  img={imagepaths.item?.snakeCase()}
                  imgname={item?.snakeCase()}
                  subcategory={item?.snakeCase()}
                  title={item}
                  key={i}
                  props={this.props}
                />
              );
            })}
          </div>
          <SpecialItems />
        </div>
      </>
    );
  }
}

export default connect(MenuList);

export const MenuItem = ({ img, title, imgname, subcategory, props }) => {
  let nextlink =
    `/client/addmenuitems?subcategory=${subcategory}&` +
    Object.QueryString({
      ...props.urlparams,
    });
  return (
    <Link to={nextlink} className="col-6 col-sm-4">
      <div
        className={`menu_item_box text-center ${title
          ?.replace(/\s+/g, "_")
          .toLowerCase()}`}
      >
        <img
          src={imgname ? imagepaths[imgname] : imagepaths.foodplaceholderimg}
          alt="menu item image"
        />
        <h4>{title.toTitleCase()}</h4>
      </div>
    </Link>
  );
};
