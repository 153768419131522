import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export class PartyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 0,
      partyOrderData: [],
      orderLists: [],
      search: {
        limit: 10,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
    };
    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Phone No.",
        dataIndex: "phone_number",
      },
      {
        title: "Event Date",
        dataIndex: "date",
      },
      {
        title: "Event Time",
        dataIndex: "time",
        render: (text) => {
          return <>{this?.convertTo12HourFormat(text) || text || "-"}</>;
        },
      },
      {
        title: "Weekday",
        dataIndex: "weekday",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
          let status = record?.status;
          let mappedStatus =
            _enum.STATUS_COLOR_MAP[status?.toLowerCase()] || "";

          return (
            <div className={`${mappedStatus} status_box`}>
              {_enum.ORDER_STATUS_MAP[text]}
            </div>
          );
        },
      },
      {
        title: "Amount",
        dataIndex: "grand_total",
        render: (text, record) => {
          return <>{text?.cur(2, "USD", "en-US") || "-"}</>;
        },
      },
      {
        title: "Actions",
        render: (text, record) => {
          return (
            <div className="action_box">
              <button
                type="button"
                className="action_btn"
                onClick={(e) => this.handlePrintOrder(e, record)}
              >
                <i className="fa fa-print"></i>
              </button>
              <button
                type="button"
                className="action_btn"
                onClick={(e) => this.viewAction(record)}
              >
                <i className="fa fa-eye"></i>
              </button>
              <button
                type="button"
                className="action_btn"
                onClick={(e) => this.editAction(record)}
              >
                <i className="fa fa-pencil"></i>
              </button>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.getMenuLists();
    this.getPartyOrders();
  }

  viewAction(record) {
    console.log("edit: ", record);
    navigate(
      `/admin/party_orders/configurations?id=${record.order_id}&readonly=true`
    );
  }

  editAction(record) {
    console.log("edit: ", record);
    navigate(
      `/admin/party_orders/configurations?id=${record.order_id}&status=${record?.status}`
    );
  }

  deleteAction() {}

  handlePrintOrder(e, record) {
    e.preventDefault();
    e.stopPropagation();

    let userData = {
      name: record?.name,
      phone_number: record?.phone_number,
      address: record?.address,
      date: record?.date,
      time: record?.time,
      weekday: record?.weekday,
      delivery_mode: record?.delivery_mode,
      items: [...record?.items],
      total: record?.total,
      discount_percent: record?.discount_percent,
      grand_total: record?.grand_total,
      discount_amount: record?.discount_amount,
      tax_percent: record?.tax_percent,
    };
    let payload = {
      ...userData,
    };
    if (record?.delivery_mode === "Delivery") {
      payload.delivery_charge = record?.delivery_charge;
    } else {
      payload.delivery_charge = "0";
    }

    // console.log({ payload });

    this.props.api.apiGeneratePdf(payload).then((res) => {
      if (res?.status === 200) {
        // console.log({ res });
        let url = res?.data?.url;
        let filename = res?.data?.file_name;
        let atag = document.createElement("a");
        atag.href = url || "";
        atag.download = filename || `invoice-${new Date().toISOString()}`;
        document.body.appendChild(atag);
        atag.click();
        document.body.removeChild(atag);
      }
    });
  }

  onPageChange(pageData) {
    console.log({ onPageChange: pageData });
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.getPartyOrders()
    );
  }

  getMenuLists() {
    let payload = {
      location: "BiryaniCityMaryLand",
    };
    this.props.api.apiGetMenuItems({}, payload).then(({ data, total }) => {
      // console.log({ apiGetMenuItems: data });
      sessionStorage.setItem("menuData", JSON.stringify(data));
      this.setState({
        menuData: data,
      });
    });
  }

  getPartyOrders() {
    this.props.api
      .apiGetPartyOrders(this.state.search)
      .then(({ data, total }) => {
        // console.log({ apiGetPartyOrders: data });
        this.setState({
          partyOrderData: data,
          rowcount: total,
        });
      });
  }
  convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours);
    let period = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.setState(
      {
        search: {
          limit: 10,
          page: 1,
          order_by: "updatedAt",
          order_direction: "DESC",
        },
      },
      () => {
        this.getPartyOrders();
      }
    );
  }

  onSearch(e, data, form) {
    let parsedPayload = parseInput(data);
    console.log({ parsedPayload, data });
    this.setState(
      {
        search: {
          ...this.state.search,
          ...parsedPayload,
        },
      },
      () => {
        this.getPartyOrders();
      }
    );
  }
  render() {
    let { partyOrderData, rowcount } = this.state;
    let { columns = [] } = this;
    return (
      <AppWrapper title="Party Orders" hidebreadcrum={true}>
        <div className="row mx-auto px-0 mb-3 floating-md-row">
          <div className="col-12 text-end">
            <Link
              className="btn modal_btn lg"
              to="/admin/party_orders/configurations?newOrder=true"
            >
              <i className="fa fa-plus me-2"></i>
              Create New
            </Link>
          </div>
        </div>
        <AppForm
          ref="form"
          onSubmit={(...args) => this.onSearch(...args)}
          className="filter-search-row flex-auto mx-auto party_order_search mb-3 pt-3"
        >
          <div className="row mx-auto px-0 align-items-center justify-content-start justify-content-md-end">
            <div className="col-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3 input_col">
              <AppInput
                type="text"
                label="Name"
                name="name"
                maxLength="50"
                // pattern="^[A-Za-z][a-zA-Z0-9\/ \\ ]*$"
                invalidmessage="Value Should be Valid Aphanumeric Content"
                placeholder="Enter Name"
              ></AppInput>
            </div>
            <div className="col-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3 input_col">
              <AppInput
                type="number"
                label="Mobile No."
                name="phone_number"
                // pattern="[0-9]{10}"
                placeholder="Enter Mobile No."
                onChange={(...args) => this.handleMobileNo(...args)}
              ></AppInput>
            </div>
            <div className="col-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3 input_col">
              <AppInput
                type="date"
                label="Event Date"
                name="date"
                placeholder="Select Date"
              ></AppInput>
            </div>
            <div className="col-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3 input_col">
              <AppInput
                type="select"
                label="Status"
                name="status"
                options={_enum.ORDER_SEARCH_STATUS}
                placeholder="Select One"
              ></AppInput>
            </div>
            <div className="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-2 d-flex align-items-center justify-content-start justify-content-md-end search_col">
              <button
                className="btn modal_btn clear_filter_btn p-0 mt-4 mt-md-0 me-4 d-flex d-xl-none"
                type="button"
                onClick={(...args) => this.resetSearchForm(...args)}
              >
                Clear All
              </button>
              <button
                className="btn modal_btn search_btn mt-4 mt-md-0 mt-xl-4"
                type="submit"
              >
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button>
              <button
                className="btn modal_btn clear_filter_btn p-0 floating_btn transparent d-none d-xl-flex border-0"
                type="button"
                onClick={(...args) => this.resetSearchForm(...args)}
              >
                Clear All
              </button>
            </div>
          </div>
        </AppForm>
        <div className="party_order_table">
          <AppTable
            data={partyOrderData}
            columns={columns}
            reorder={true}
            total={rowcount}
            onChange={(...arg) => this.onPageChange(...arg)}
            viewable={false}
            editable={false}
            deletable={false}
          ></AppTable>
        </div>
      </AppWrapper>
    );
  }
}

export default connect(PartyOrders);
