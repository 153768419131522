
/* global imagepaths connect React Component */
import { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import './App.scss';
import AppLayout from "./layout/AppLayout";
import ErrorView from './utils/errors/ErrorView';

const checkLogin = function (props) {
  return true;
}

const Navigate = (props) => {
  window.navigate = useNavigate();
  checkLogin(props);
  return props.children;
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error });
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorView message={this.state.error?.message} />;
    }
    return this.props.children;
  }
}
export class App extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("app");
    this.store([
      { name: 'requests', value: [] },
    ]);
  }
  render() {
    return (
      <ErrorBoundary>
        <Navigate>
          <Suspense>
            <AppLayout>
              {this.props.children}
            </AppLayout>
          </Suspense>
        </Navigate>
      </ErrorBoundary>
    )
  }
}

export default connect(App);
