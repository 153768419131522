export default [
    {
        title: "Dashboard",
        itemId: "/admin/dashboard",
        icon: "la la-user",
        iconStyle: imagepaths.Dashboard,
        iconActive: imagepaths.DashboardActive,
    },
    // {
    //     title: "Dine in Orders",
    //     itemId: "/admin/dine_in_orders",
    //     icon: "la la-user",
    // },
    {
        title: "Party Orders",
        itemId: "/admin/party_orders",
        icon: "la la-user",
        iconStyle: imagepaths.checkListIcon,
        iconActive: imagepaths.checkListIconActive,
    },
]