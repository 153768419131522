import React, { Component } from "react";

export class AppFormData extends Component {
  render() {
    let { props } = this;
    let {
      data,
      size = 2,
      fields = {},
      submitText = "SUBMIT",
      onClose,
      disableallfields = false,
      disablefields = [],
      readOnlyfields = [],
      editdata,
      viewMode,
    } = props;
    let list = Object.entries(data).chunk(size);
    let getField = (label) => fields[label] || {};
    let formAttrs = Object.except(props, [
      "data",
      "fields",
      "size",
      "submitText",
    ]);
    return (
      <AppForm {...formAttrs}>
        {list.map((row, i) => (
          <Row className="mx-0" key={i}>
            {row.map(([label, value], j) => (
              <Col
                key={j}
                className={Object.className({
                  invisible: label === "item_image",
                })}
              >
                <AppInput
                  name={label}
                  id={fields?.id}
                  label={label.toTitleCase()}
                  defaultValue={value}
                  {...(editdata.hasOwnProperty("item_image")
                    ? {
                        imageurl: editdata["item_image"],
                      }
                    : {})}
                  disabled={disableallfields}
                  {...(disablefields.includes(label) &&
                  Object.keys(editdata).length > 0
                    ? {
                        disabled: true,
                      }
                    : { disabled: false })}
                  {...(readOnlyfields.includes(label) &&
                  Object.keys(editdata).length > 0
                    ? {
                        readOnly: true,
                      }
                    : { readOnly: false })}
                  {...getField(label)}
                ></AppInput>
              </Col>
            ))}
          </Row>
        ))}
        <div className="row mt-4">
          <div className="col-xl-6 mb-3"></div>
          <div className="col-xl-6 mb-3 text-end d-flex justify-content-end">
            <button
              type="reset"
              className={Object.className({
                "btn cancel-btn": true,
                "m-auto": disableallfields,
              })}
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
            <div
              className={Object.className({
                "ms-2": true,
                hidden: disableallfields || viewMode,
              })}
            >
              <button type="submit" className="btn modal_btn p-0">
                {submitText}
              </button>
            </div>
          </div>
        </div>
      </AppForm>
    );
  }
}

export default AppFormData;
