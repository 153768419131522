import React, { Component } from "react";
import mixinsFunc from "../mixins";
import { Link } from "react-router-dom";
import MenuModal from "./MenuModal";
import MenuPdfModal from "./MenuPdfModal";

export class MenuManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("MenuManagement");
    this.mixins = mixinsFunc(this);
    this.state = {
      menuData: [],
      rowcount: 0,
      currentPage: 1,
      search: {
        limit: 10,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
        sort_by_name: false,
        location: "BiryaniCityMaryLand",
      },
      categoryList: [],
      subCategoryList: [],
      subCategoryOptions: [],
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      //
      menuPdfEditData: {},
      menuPdfViewMode: false,
      menuPdfEditMode: false,
      menuPdfPopupfields: {},
      menuPdfPopupdata: {},
    };
    this.columns = [
      {
        title: "Category",
        dataIndex: "item_category",
      },
      {
        title: "Sub Category",
        dataIndex: "item_sub_category",
      },
      {
        title: "Name",
        dataIndex: "item_name",
        sorter: true,
        onHeaderCell: (record) => ({
          onClick: () => this.handleSorting(record),
        }),
      },
      {
        title: "Priority",
        dataIndex: "item_order",
      },
      // {
      //   title: "Item Description",
      //   dataIndex: "item_description",
      //   // sorter: (a, b) => a.item_description.length - b.item_description.length,
      //   render: (text, record) => {
      //     return (
      //       <span
      //         className="text_truncate cursor-pointer"
      //         title={record?.item_description}
      //         onClick={() => alert(record?.item_description)}
      //       >
      //         {record?.item_description}
      //       </span>
      //     );
      //   },
      // },
      {
        title: "Price",
        dataIndex: "item_retail_price",
        render: (text, record) => {
          return <>${text}</>;
        },
      },
      {
        title: "Actions",
        render: (text, record) => {
          return (
            <div className="action_box">
              <button
                type="button"
                className="action_btn"
                onClick={(e) => this.editAction(record)}
              >
                <i className="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                className="action_btn"
                onClick={(e) => this.deleteAction(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    ];
    this.modalFields = [
      {
        type: "select",
        name: "item_category",
        label: "Category",
        options: Object.keys(menucategories).options(),
        // onChange: (value) => {
        //   let subcategory = this.modalFields.find(
        //     (o) => o.name == "item_sub_category"
        //   );
        //   console.log(value[0].key, subcategory);
        //   if (menucategories[value[0].key].length > 0) {
        //     subcategory.options = Object.assign(
        //       subcategory.options,
        //       menucategories[value[0].key].options()
        //     );
        //   } else {
        //     subcategory.options = [];
        //   }
        // },
        onChange: (v) => this.handleCategoryChange(v),
        required: true,
      },
      {
        type: "select",
        name: "item_sub_category",
        label: "Sub Category",
        id: "subcategory-input",
        options: [],
        required: true,
      },
      {
        type: "text",
        name: "item_name",
        label: "Name",
        required: true,
      },
      {
        type: "number",
        name: "item_retail_price",
        label: "Price",
        step: "0.01",
        min: "0",
        max: "100",
        onInput: (e) => this.decimalValidate(e),
        required: true,
      },
      {
        type: "number",
        name: "item_order",
        label: "Priority",
        required: true,
      },
      {
        type: "file",
        foldername: "biryanihub",
        fileType: "item_image",
        name: "attachment_id",
        label: "Upload Image",
        accept: "image/*",
        required: true,
      },
      {
        type: "textarea",
        name: "item_description",
        label: "Enter Description",
      },
    ];
    this.pdfMenuModalFields = [
      {
        type: "text",
        name: "menu_name",
        label: "Name",
        required: true,
      },
      {
        type: "file",
        label: "Upload Menu",
        foldername: "biryanihub",
        fileType: "menu_file",
        name: "attachment_id",
        accept: "application/pdf",
        required: true,
      },
    ];
    this.menuModalRef = React.createRef();
    this.pdfMenuModalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchList(this.state.search.location);
    let menuData = JSON.parse(sessionStorage.getItem("menuData"));

    const categoryListArr = menuData?.map((item) => item.item_category);
    const categoryList = [...new Set(categoryListArr)];

    const subCategoryListArr = menuData?.map((item) => item.item_sub_category);
    const subCategoryList = [...new Set(subCategoryListArr)];
    console.log({ categoryList, subCategoryList });

    this.setState({
      categoryList,
      subCategoryList,
    });
  }

  handleCategoryTypeChange(v) {
    console.log({ handleCategoryTypeChange: v[0] });

    if (v) {
      let categoryType = v[0]?.key;
      console.log({ categoryType });

      let subCategoryInput = document.querySelector(
        "[name='appinput-item_sub_category'] .react-dropdown-select"
      );

      if (categoryType === "Appetizers" || categoryType === "Main Course") {
        subCategoryInput.classList.remove("pe-none");

        let options = menucategories[categoryType];
        this.setState({ subCategoryList: options });
      } else {
        subCategoryInput.classList.add("pe-none");
      }

      let itemSubCategoryInput = document.querySelector(
        `[name='appinput-item_sub_category'].form-group`
      );
      itemSubCategoryInput.vnode.refs.select.clearAll();
    }
  }

  handleCategoryChange([val]) {
    // console.log({ val });
    let options = menucategories[val.key].options();
    // console.log({ options });
    if (val) {
      this.setState((state) => ({
        ...state,
        subCategoryOptions: [],
      }));
      let subCatInput = document.querySelector(
        "[name='appinput-item_sub_category'].form-group"
      );
      let { values: subCatOptions = [] } = subCatInput.vnode.refs.select.state;
      if (subCatOptions.length) {
        subCatInput.vnode.refs.select.clearAll();
      }
      this.setState({
        subCategoryOptions: options,
      });
    }
  }

  decimalValidate(e) {
    const inputValue = e.target.value;
    if (!/^\d+(\.\d{0,2})?$/.test(inputValue)) {
      e.target.value = inputValue.slice(0, -1);
    }
  }
  removeEmpty(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      }
    });
    return obj;
  }

  fetchList(value) {
    let payload = {
      location: value,
    };
    this.props.api
      .apiGetMenuItems(this.removeEmpty({ ...this.state.search }), payload)
      .then(({ data, total }) => {
        console.log({ apiGetMenuItems: data });
        this.setState({
          menuData: data,
          rowcount: total,
        });
      });
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  editAction(v) {
    console.log("edit: ", v);
    this.openMenuModal();

    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let editdata = {
      item_category: v?.item_category,
      item_sub_category: v?.item_sub_category,
      item_name: v?.item_name,
      item_retail_price: v?.item_retail_price,
      item_order: v?.item_order,
      attachment_id: v?.attachment_id,
      item_description: v?.item_description,
    };
    this.setState({
      popupfields: fields,
      popupdata: editdata,
      editMode: true,
      viewMode: false,
      editData: editdata,
    });
    // update sub category based on category
    let subcategory = this.modalFields.find(
      (o) => o.name == "item_sub_category"
    );
    subcategory.options = Object.assign(
      subcategory.options,
      menucategories[v.item_category].options()
    );
  }

  deleteAction(value) {
    console.log("delete action: ", value);
    let payload = {
      item_name: value.item_name,
      location: this.state.search.location,
    };

    confirm(`Are you sure want to delete this item ?`).promise.then(() => {
      this.props.api.apiDeleteMenuItem(payload).then(() => {
        this.fetchList(this.state.search.location);
      });
    });
  }

  openMenuModal() {
    this.menuModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#add_menu_modal #bsmodalclose").click();
  }
  // ----------------------------------------------------------------

  addPdfModalFields() {
    let fields = (this.pdfMenuModalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      menuPdfPopupfields: fields,
      menuPdfPopupdata: formdata,
    });
  }

  openMenuPdfModal() {
    this.menuPdfPopupdata.current.click();
  }

  closeMenuPdfModal() {
    document.querySelector("#add_pdf_menu_modal #bsmodalclose").click();
  }

  handleMenuPdfSubmit(event, data, form) {
    event.preventDefault();
    // console.log("formData", data, form);

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Menu Updated successfully"
            : "Menu added successfully"
        }`
      );
      form.reset();
      this.closeMenuPdfModal();
      this.fetchList(this.state.search.location);
    };
    let payload = {
      ...Object.only(data, ["menu_name", "attachment_id"]),
    };
    console.log({ payload });
    this.props.api.apiAddMenuFile(payload).then(resolveList);
  }

  // ----------------------------------------------------------------
  handleLocationChange(v) {
    console.log({ handleLocationChange: v[0].label });
    this.setState({
      search: {
        location: v[0].label,
      },
    });
    this.fetchList(v[0].label);
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    console.log("formData", data);

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Item Updated successfully"
            : "Item added successfully"
        }`
      );
      form.reset();
      this.closeModal();
      this.fetchList(this.state.search.location);
    };
    if (this.state.editMode) {
      let editPayload = {
        ...Object.except(data, ["imageUrl", "fileName", "file"]),
        item_image: data?.imageUrl || data?.item_image,
        item_retail_price: Number(data.item_retail_price),
        item_description: data.item_description,
        item_order: Number(data.item_order),
        location: this.state.search.location,
        //
        item_category: data?.item_category,
        item_sub_category: data?.item_sub_category,
      };
      // console.log({ editPayload });
      this.props.api.apiUpdateMenuItem(editPayload).then(resolveList);
    } else {
      let addPayload = {
        ...Object.except(data, ["imageUrl", "fileName", "file"]),
        item_image: data?.imageUrl,
        item_retail_price: Number(data.item_retail_price),
        item_order: Number(data.item_order),
        location: this.state.search.location,
      };
      // console.log({ addPayload });
      this.props.api.apiAddMenuItem(addPayload).then(resolveList);
    }
  }

  handleSorting(record) {
    console.log(record);
    this.setState(
      {
        search: {
          ...this.state.search,
          sort_by_name: !this.state.search.sort_by_name,
        },
      },
      (state) => this.fetchList(this.state.search.location)
    );
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      currentPage: pagination.current,
    });
    if (pagination.current !== this.state.search.page) {
      this.onPageChange(pagination);
    }
    // Ignore sorting actions
    if (sorter.order) {
      console.log("Sorting prevented.");
    }
  };

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
          sort_by_name: this.state.search.sort_by_name,
        },
      },
      (state) => this.fetchList(this.state.search.location)
    );
  }
  updateFields(fields) {
    fields.find((o) => o.name === "item_sub_category").options =
      this.state.subCategoryOptions;

    return fields;
  }

  onSearch(e, data, form) {
    let parsedPayload = parseInput(data);
    console.log({ parsedPayload, data });
    this.setState(
      {
        currentPage: 1,
        search: {
          ...this.state.search,
          page: 1,
          ...parsedPayload,
        },
      },
      () => {
        this.fetchList(this.state.search.location);
      }
    );
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    let menuData = JSON.parse(sessionStorage.getItem("menuData"));

    const categoryListArr = menuData?.map((item) => item.item_category);
    const categoryList = [...new Set(categoryListArr)];

    const subCategoryListArr = menuData?.map((item) => item.item_sub_category);
    const subCategoryList = [...new Set(subCategoryListArr)];
    console.log({ categoryList, subCategoryList });

    this.setState({
      categoryList,
      subCategoryList,
    });
    this.setState(
      {
        currentPage: 1,
        search: {
          limit: 10,
          page: 1,
          order_by: "updatedAt",
          order_direction: "DESC",
          location: "BiryaniCityMaryLand",
        },
      },
      () => {
        this.fetchList(this.state.search.location);
        let subCategoryInput = document.querySelector(
          "[name='appinput-item_sub_category'] .react-dropdown-select"
        );
        subCategoryInput.classList.remove("pe-none");
      }
    );
  }

  render() {
    let {
      menuData,
      rowcount,
      currentPage,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
      //
      menuPdfEditData,
      menuPdfViewMode,
      menuPdfEditMode,
      menuPdfPopupfields,
      menuPdfPopupdata,
      categoryList,
      subCategoryList,
    } = this.state;
    let { columns = [], modalFields } = this;
    modalFields = this.updateFields(modalFields);

    return (
      <AppWrapper title="Menu Management" hidebreadcrum={true}>
        <div className="row mx-auto mb-3 floating-md-row">
          <div className="col-4 col-md-6 d-none d-md-flex"></div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-start justify-content-end">
            <Link
              className="btn modal_btn lg bordered_variant me-3"
              ref={this.pdfMenuModalRef}
              data-bs-toggle="modal"
              data-bs-target="#add_pdf_menu_modal"
              onClick={(...args) => this.addPdfModalFields(...args)}
            >
              <i className="fa fa-upload me-2"></i>
              Add Menu
            </Link>
            <Link
              className="btn modal_btn lg"
              ref={this.menuModalRef}
              data-bs-toggle="modal"
              data-bs-target="#add_menu_modal"
              onClick={(...args) => this.addModalFields(...args)}
            >
              <i className="fa fa-plus me-2"></i>
              Add Item
            </Link>
          </div>
        </div>
        <AppForm
          ref="form"
          onSubmit={(...args) => this.onSearch(...args)}
          className="filter-search-row flex-auto mx-auto menu_management_search mb-3 pt-3"
        >
          <div className="row mx-auto px-0">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <AppInput
                type="select"
                label="Category"
                name="item_category"
                options={Object.keys(menucategories).options()}
                onChange={(...args) => this.handleCategoryTypeChange(...args)}
                placeholder="Category"
              ></AppInput>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <AppInput
                type="select"
                label="Sub Category"
                name="item_sub_category"
                options={subCategoryList?.options()}
                placeholder="Enter Sub Category"
              ></AppInput>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <AppInput
                type="text"
                label="Name"
                name="item_name"
                placeholder="Name"
              ></AppInput>
            </div>
            <div className="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-2 d-flex align-items-center justify-content-start justify-content-md-end search_col">
              <button
                className="btn modal_btn clear_filter_btn p-0 mt-4 mt-md-0 me-4 d-flex d-xl-none"
                type="button"
                onClick={(...args) => this.resetSearchForm(...args)}
              >
                Clear All
              </button>
              <button
                className="btn modal_btn search_btn mt-4 mt-md-0 mt-xl-4"
                type="submit"
              >
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button>
              <button
                className="btn modal_btn clear_filter_btn p-0 floating_btn transparent d-none d-xl-flex border-0"
                type="button"
                onClick={(...args) => this.resetSearchForm(...args)}
              >
                Clear All
              </button>
            </div>
          </div>
        </AppForm>
        <div className="menu_table">
          <AppTable
            data={menuData}
            columns={columns}
            reorder={true}
            total={rowcount}
            pageSize={10}
            // showPagination={false}
            currentPage={currentPage}
            onChange={(...arg) => this.handleTableChange(...arg)}
            viewable={false}
            editable={false}
            deletable={false}
          ></AppTable>
        </div>
        <MenuModal
          id="add_menu_modal"
          title={`${editMode ? "Update Item" : "Add Item"}`}
          submitText={`${editMode ? "Update" : "Add"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          // disableallfields={editMode ? true : false}
          // disablefields={["item_category", "item_sub_category"]}
          readOnlyfields={["item_name"]}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
            })
          }
        />
        <MenuPdfModal
          id="add_pdf_menu_modal"
          title={`${menuPdfEditMode ? "Update Menu" : "Add Menu"}`}
          submitText={`${menuPdfEditMode ? "Update" : "Add"}`}
          editdata={menuPdfEditData}
          data={menuPdfPopupdata}
          fields={menuPdfPopupfields}
          onSubmit={(...args) => this.handleMenuPdfSubmit(...args)}
          onClose={() =>
            this.setState({
              menuPdfPopupdata: {},
              menuPdfEditData: {},
              menuPdfEditMode: false,
              menuPdfViewMode: false,
            })
          }
        />
      </AppWrapper>
    );
  }
}

export default connect(MenuManagement);
